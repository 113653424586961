<script setup lang="ts">
    import type { UspItemData, UspWidgetData } from '~/@types/cms';
    import type { IWidgetProps } from '~/composables/widgets';

    /**
     * USP widget container
     * renders a list of USP info blocks
     */
    const props = defineProps<IWidgetProps<UspWidgetData>>();
    const appStore = useAppStore();
    const { data, getValueSequence, resolveUrlLink } = useWidget(props);
    const items = computed(() => {
        if (data.value) {
            const ret = getValueSequence(data.value);
            if (ret) {
                return ret.map((u: UspItemData) => {
                    const { url, label } = resolveUrlLink(u.link, appStore.getReference);
                    return {
                        content: u.title,
                        icon: u.icon?.data.icon,
                        toLink: url,
                    };
                });
            }
        }
        return [];
    });
</script>
<template>
    <mol-usp-bar
        :items="items"
        class="cms-usp-widget-container" />
</template>
